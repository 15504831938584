@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Black.otf") format("opentype");
  font-weight: 900;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-ExtraBold.otf") format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-ExtraBoldItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-ExtraLight.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-ExtraLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Italic.otf") format("opentype");
  font-weight: normal 400;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Light.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Regular.otf") format("opentype");
  font-weight: normal 400;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-SemiBold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-Thin.otf") format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "Idemia";
  src: local("Idemia"), url("IDEMIA-FONT-V02-20180607-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}